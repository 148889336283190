<template>
    <el-select v-model="model" filterable :placeholder="`请选择${col.value}`" reserve-keyword remote :remote-method="getList"
               :loading="loading" :value-key="keyField" style="width: 100%" @change="valChange">
        <el-option v-for="item in list" :key="item[keyField]" :label="item[valueField]" :value="item"></el-option>
    </el-select>
</template>

<script>
    import api from '@/util/api'
    export default {
        props: {
            form: {},
            col: {},
            value: {},
        },
        // watch: {
        //     value(newVal, oldVal) {
        //         console.log(`${oldVal} => ${newVal}`)
        //     },
        // },
        data() {
            return {
                model: {},
                list: [],
                loading: false,

                api: '',
                keyField: '',
                valueField: '',
            }
        },
        created() {
            this.api = this.col.param.api
            this.keyField = this.col.param.keyField
            this.valueField = this.col.param.valueField

            if (this.form[this.keyField]) {
                const obj = {}
                obj[this.keyField] = this.form[this.keyField]
                obj[this.valueField] = this.form[this.valueField]
                this.model = obj
                this.list = [obj]
            }

            this.getList()
        },
        methods: {
            getList(keyword) {
                this.loading = true
                api.get(this.api, {keyword})
                    .then(res => {
                        this.list = res.data
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            valChange() {
                this.col.param.assignment(this.model, this.form)
            },
        }
    }
</script>