import api from '@/util/api';
// import help from '@/util/help';
import variable from '@/util/variable'

// 防抖
function _debounce(fn, delay) {
    delay = delay || 200;
    let timer;
    return function () {
        const th = this;
        const args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            timer = null;
            fn.apply(th, args);
        }, delay);
    };
}


export default {
    props: ['title'],
    data() {
        return {
            loading: false,
            list: [],

            searchCols: [],

            staticData: {},

            currentStatus: '',
            pageParam: {
                page: 1,
                pageSize: variable.basePageSize,
                total: 0,
                error: '',
            },

            dialog: {
                show: false,
                loading: false,
                edit: false,
                loaded: false,
                form: {},

                isExist: false,
            },

            isSearchLeft:false,
        }
    },
    created() {
        this.init()

        this.$emit('pageChange', {
            cols: this.cols,
            tableName: this.tableName || '',
        })

        this.initStatic()

        // 计算需要验证的列
        if (this.existParam && this.existParam.colKeys) {
            const colsMap = []
            this.cols.forEach(col => {
                if (this.existParam.colKeys.includes(col.key)) {
                    colsMap[col.key] = col
                }
            })
            this.$set(this.existParam, 'colsMap', colsMap)
        }
    },
    methods: {
        //左侧筛选
        searchShowLeft(){
            console.log('点击');
            this.isSearchLeft = true
        },
        //关闭左侧筛选框
        setSearchClose(){
            this.isSearchLeft = false
        },
        setHandleFilter(cols){
            this.searchCols = cols
            this.init()
        },
        // 获取统计数据
        initStatic() {
            if (this.api.static) {
                this.$api.get(this.api.static).then(res => {
                    if (res.code === this.$variable.successCode)  {
                        this.$emit('passStatic', {
                            data: this.formatStatic ? this.formatStatic(res.data) : res.data,
                            cols: this.staticCols
                        })
                        this.staticData = res.data
                    }
                })
            }
        },

        existYes() {
            this.dialog.isExist = true
        },
        existNo() {
            this.dialog.isExist = false
        },
        isExist() {
            this.existNo()
            this.isExistFun()
        },
        isExistFun: _debounce(function () {
            let flag = true
            this.existParam.colKeys.forEach(colKey => {
                const col = this.existParam.colsMap[colKey]
                if (col && col.required && !this.dialog.form[colKey]) {
                    flag = false
                }
            })

            flag && this.isExistToServer()
        }, 3000),

        isExistToServer() {
            const query = {
                id: this.dialog.form.id,
            }
            this.existParam.colKeys.forEach(colKey => {
                query[colKey] = this.dialog.form[colKey]
            })
            this.$api.post(this.api.exist, query)
                .then(res => {
                    if (res.data) {
                        this.existYes()
                    }
                })
        },

        init() {
            this.list = []
            this.pageParam.page = 1
            this.pageParam.total = 0
            this.currentStatus = this.$parent.status
            this.getList()
        },
        getList() {
            this.loading = true
            api.post(this.api.page, this.getParam(), {'Visit-Id': this.$help.getVisitId(this.visitId)})
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        if(this.setNewList){
                            this.list = this.setNewList(this.$help.deepClone(res.data.records))
                        }else{
                            this.list = res.data.records
                        }
                        this.pageParam.total = res.data.totalCount
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getParam1() {
            return this.getParam()
        },
        getParam() {
            const obj = {
                conditionList: [],
                status: this.currentStatus,
                page: {
                    pageNo: this.pageParam.page,
                    pageSize: this.pageParam.pageSize,
                },
            }

            if (this.defaultSort) {
                obj.sort = this.defaultSort
            }

            if (this.currentStatus) {
                obj.conditionList.push({
                    name: 'status',
                    op: 'eq',
                    value: this.currentStatus,
                })
            }
            this.searchCols.forEach(item => {
                if (item.model) {
                    switch (item.field.listQueryModel) {
                        case variable.searchType.LIKE:
                            obj.conditionList.push({
                                name: item.field.fieldName,
                                op: 'like',
                                value: item.model,
                            })
                            break;
                        case variable.searchType.SELECT:
                            // obj.conditionList.push({
                            //     name: item.field.fieldName,
                            //     op: 'in',
                            //     value: item.model,
                            // })
                            if (item.model && item.model.length > 0) {
                                obj.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'in',
                                    value: item.model,
                                })
                            }
                            break;
                        default:
                            if (item.model && item.model.length === 2 && !item.model.includes(undefined)) {
                                obj.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'ge',
                                    value: item.model[0],
                                })
                                obj.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'le',
                                    value: item.model[1]
                                })
                            }
                    }
                }
            })
            return obj
        },
        sort(param) {
            this.sortParam = {}
            if (param) {
                this.sortParam = param
            }
            this.init();
        },
        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },
        handleCurrentChange(val) {
            this.pageParam.page = val
            this.getList()
        },

        add() {
            this.dialog.show = true
            this.dialog.loading = false
            this.dialog.edit = false
            this.dialog.loaded = true
            this.dialog.form = {}
        },

        modify(row) {
            this.dialog.show = true
            this.dialog.loading = false
            this.dialog.edit = true
            this.dialog.loaded = false
            if (this.api.detail) {
                this.$api.get(this.api.detail, {id: row.id})
                    .then(res => {
                        this.dialog.form = res.data
                        this.dialog.loaded = true
                    })
            } else {
                this.dialog.form = this.$help.deepClone(row)
                this.dialog.loaded = true
            }
        },

        del(row) {
            this.$store.commit('confirm', {
                content: '确定删除吗？',
                callback:(next, back) => {
                    api.delete(this.api.del, {id: row.id})
                        .then(res => {
                            if (res.code === this.$variable.successCode) {
                                this.$store.commit('success', res.message)
                                this.getList()
                                next()
                            } else {
                                this.$store.commit('error', res.message)
                            }
                        })
                        .finally(back)
                }
            })
        },

        save(param) {
            if (this.rich && this.rich.key) {
                this.dialog.form[this.rich.key] = param.rich
            }
            this.dialog.loading = true
            api.post(this.dialog.edit ? this.api.modify : this.api.add, this.dialog.form)
                .then(res => {
                    if (res.code === this.$variable.SUCCESS_CODE) {
                        this.$store.commit('success', res.message)
                        this.getList()
                        this.dialog.show = false
                    } else {
                        this.$store.commit('error', res.message)
                    }
                })
                .finally(() => {
                    this.dialog.loading = false
                })
        },
    }
}