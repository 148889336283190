<style lang="scss">
    @import "~@/assets/css/var";
    .my-drawer {
        .edit-desc {
            padding: 20px;
            .el-descriptions-item__label {
                text-align: right;
                width: 160px;
                vertical-align: middle;
            }
            .el-descriptions-item__content {
                vertical-align: middle;
            }
            .required {
                color: $red;
            }
        }
        .edit-btn {
            text-align: center;
            padding-bottom: 20px;
            .el-button {
                width: 300px;
            }
        }
    }
</style>
<template>
    <el-drawer class="my-drawer" :visible.sync="show" :size="max ? '100%' : size" :show-close="false" :before-close="close">
        <template slot="title">
            <div class="my-drawer__title">{{title}}<slot name="title"></slot></div>
            <span>
                <i class="el-icon-close" @click="close"></i>
            </span>
        </template>

        <div class="edit-desc" v-if="dialog.loaded">
            <el-descriptions :column="max ? 2 : 1" border>
                <template v-for="col in cols">
                    <el-descriptions-item v-if="col.type !== 'rich' && !col.hEdit" :key="col.key" :label="col.value">
                        <template slot="label">
                            <div :class="col.required ? 'required' : ''">
                                <span v-if="col.required">*</span> {{col.value}}
                            </div>
                        </template>

                        <template v-if="col.type === 'fuzzy'">
                            <!--<el-input class="clickable" v-model="form[col.key]" :placeholder="`请选择${col.value}`" readonly @click.native="openFuzzy(col)">-->
                            <!--<i slot="suffix" style="padding-top: 8px;" class="el-icon-search"></i>-->
                            <!--</el-input>-->
                            <edit-select :value="form[col.key]" :form="form" :col="col"></edit-select>
                        </template>

                        <template v-else-if="col.type === 'multipleDrug'">
                            <edit-multiple-drug :value="form[col.key]" :form="form" :col="col"></edit-multiple-drug>
                        </template>

                        <template v-else-if="col.type === 'textarea'">
                            <el-input v-model="form[col.key]" type="textarea" :placeholder="`请输入${col.value}`"></el-input>
                        </template>

                        <template v-else-if="col.type === 'date'">
                            <el-date-picker v-model="form[col.key]" style="width: 100%"
                                            :placeholder="`请选择${col.value}`" type="date" value-format="yyyy-MM-dd"></el-date-picker>
                        </template>

                        <template v-else-if="col.type === 'file'">
                            <edit-upload :value="form[col.key]" :form="form" :col="col"></edit-upload>
                        </template>

                        <template v-else>
                            <el-input v-model="form[col.key]" :placeholder="`请输入${col.value}`"></el-input>
                        </template>
                    </el-descriptions-item>
                </template>
            </el-descriptions>
        </div>

        <div v-if="rich.key" style="margin-bottom: 20px; padding: 0 32px 0 20px">
            <ts-ueditor ref="ue"></ts-ueditor>
        </div>

        <div class="edit-btn">
            <el-button type="primary" size="large" :loading="loading" @click="save">保存</el-button>
        </div>
    </el-drawer>
</template>

<script>

    import EditSelect from './EditSelect'
    import EditUpload from './EditUpload'
    import EditMultipleDrug from './EditMultipleDrug'

    export default {
        components: {EditSelect, EditUpload, EditMultipleDrug},
        props: {
            show: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: '1000px'
            },
            title: {},
            rich: {
                type: Object,
                default: () => ({})
            },

            cols: {
                type: Array,
                default: () => []
            },
            form: {
                type: Object,
                default: () => ({})
            },
            dialog: {
                type: Object,
                default: () => ({})
            },
        },
        data() {
            return {
                max: false,
                fuzzy: {
                    show: false,
                    title: '',
                    col: {},
                    component: null,
                },
            }
        },
        watch: {
            'dialog.loaded': {
                immediate: true,
                handler(val) {
                    if (val && this.rich.key && this.form[this.rich.key]) {
                        this.setUeContent(this.form[this.rich.key])
                    }
                },
            },
        },
        methods: {
            setUeContent(content) {
                if (this.$refs.ue) {
                    this.$refs.ue.show(content)
                } else {
                    setTimeout(() => {
                        this.setUeContent(content)
                    }, 300)
                }
            },
            close() {
                this.$emit('update:show', false);
                return false;
            },
            save() {
                for (let i = 0; i < this.cols.length; i += 1) {
                    const col = this.cols[i]
                    if (col.required && !this.form[col.key]) {
                        this.$store.commit('error', `${col.value}不能为空`)
                        return false
                    }
                }
                const param = {}
                // 添加UE内容
                if (this.rich.key) {
                    param.rich = this.$refs.ue.get()
                }

                this.$emit('save', param)
            },
        }
    }
</script>