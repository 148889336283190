<style lang="scss">
.close_icon {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ff4949;
    margin-left: 20px;
    cursor: pointer;
}
</style>
<template>
    <div class="ts-upload" style="display: flex;">
        <el-upload ref="upload" :action="action" :auto-upload="false" :multiple="false" :show-file-list="false"
            :data="postData" :on-change="handleChange" :on-success="success" :on-error="failed"
            :before-upload="beforeUpload">
            <el-button class="btn-blue" :loading="fileLoading">
                <template v-if="fileLoading">上传中...</template>
                <template v-else-if="file">更换文件</template>
                <template v-else>选择文件</template>
            </el-button>
        </el-upload>
        <el-link style="margin-left: 10px;" :underline="false" v-if="file" type="primary" :href="file"
            target="_blank">查看文件</el-link>
        <i class="el-icon-close close_icon" v-show="file" @click="del"></i>
    </div>
</template>

<script>
import qiniu from '@/util/qiniu'
export default {
    props: {
        form: {},
        col: {},
        value: {},
    },
    // watch: {
    //     value(newVal) {
    //         this.file = newVal
    //     },
    // },
    data() {
        return {
            fileLoading: false,
            action: qiniu.upload,
            postData: {
                token: '',
                key: '',
            },
            file: '',
        }
    },
    created() {
        if (this.value) {
            this.file = this.value
        }
    },
    methods: {
        handleChange() {
            if (this.read || this.fileLoading) {
                return
            }
            this.fileLoading = true
            qiniu.getToken().then(res => {
                if (res.success) {
                    this.postData.token = res.data
                    this.$refs.upload.submit();
                }
            })
        },
        beforeUpload(file) {
            const name = file.name
            const splitStart = name.lastIndexOf('.')

            this.postData.key = `${name.substr(0, splitStart)}-${new Date().Format('yyyyMMddhhmmss')}${name.substr(splitStart)}`
        },
        success(obj) {
            this.file = obj.data.url
            this.$set(this.form, this.col.key, this.file)
            setTimeout(() => {
                this.fileLoading = false
            }, 300)
        },
        failed(obj) {
            console.log(obj)
            setTimeout(() => {
                this.fileLoading = false
            }, 300)
        },
        del() {
            this.file =  ''
            this.$emit('update:value', '')
        },
    }
}
</script>