<style lang="scss">
.my-table {
    width: 100%;

    .table-operate-panel {
        text-align: center;

        .el-link {
            font-size: 12px;
        }

        .el-link+.el-link {
            // margin-left: 10px;
            margin-top: 5px;
        }
    }

    .el-tooltip {
        white-space: unset !important;
    }

    .cell {
        position: relative;

        .table_popover {
            position: absolute;
            left: 5px;
            top: -4px;

            .el-button {
                padding: unset;
            }
        }
    }
}
</style>

<template>
    <div ref="ele" class="my-table">
        <div style="display: flex;">
            <div :style="{ 'height': realHeight + 'px', }" v-if="isSearchLeft">
                <ts-search-left :searchCols="searchCols" :table-name="searchParam.tableName" :cols="searchParam.cols"
                    @searchClose="searchClose" @handleFilter="handleFilter"></ts-search-left>
            </div>
            <el-table ref="table" v-if="tableLoaded" :border="!hideBorder" :data="data" :height="realHeight"
                v-loading="loading" :sort-orders="['asc', 'desc', null]" stripe tooltip-effect="light"
                @sort-change="handleSort">

                <!--序列-->
                <el-table-column fixed type="index" width="50" label="#" align="center"></el-table-column>

                <el-table-column v-for="(item, i) in list" :key="i" :prop="item.key" show-overflow-tooltip
                    :label="item.value" :min-width="item.width" :align="getAlign(item.align)" :fixed="item.fixed"
                    :sortable="getOrder(item.sort)">
                    <template #header>
                        <template v-if="item.isIcon">
                            <el-popover class="table_popover " placement="top-start" width="100" trigger="hover">
                                <p style="font-size:10px;margin: 0 10px;">{{ item.iconText }}</p>
                                <el-button slot="reference" class="txt-btn" type="text" icon="el-icon-info"
                                    style="color: #45d75f;"></el-button>
                            </el-popover>
                            <p v-html="item.value"></p>
                        </template>
                        <template v-else>
                            <p v-html="item.value"></p>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <slot :name="'slot_' + item.key" :props="scope">
                            <template v-if="item.showKey">
                                {{ scope.row[item.showKey] }}
                            </template>
                            <template v-else-if="item.key === 'component'">
                                <template v-if="scope.row.component && scope.row.component.length > 0">
                                    <span v-for="(item, index) in scope.row.component" :key="index">
                                        <span v-if="typeof (item) === 'object'">
                                            <multi-level-jump :obj="item" :btnList="componentList"
                                                :name="'component'"></multi-level-jump>
                                        </span>
                                        <template v-else>{{ item }}</template>
                                    </span>
                                </template>
                                <p v-else>--</p>
                            </template>
                            <template v-else-if="item.key === 'indication'">
                                <template v-if="scope.row.indication && scope.row.indication.length > 0">
                                    <span v-for="(item, index) in scope.row.indication" :key="index">
                                        <span v-if="typeof (item) === 'object'">
                                            <multi-level-jump :obj="item" :btnList="indicationList"
                                                :name="'indication'"></multi-level-jump>
                                        </span>
                                        <template v-else>{{ item }}</template>
                                    </span>
                                </template>
                                <p v-else>--</p>
                            </template>
                            <template v-else-if="item.type === 'file'">
                                <el-link v-show="scope.row[scope.column.property]" size="mini" target="_blank"
                                    type="primary" style="font-size: 12px" :href="scope.row[scope.column.property]"
                                    :underline="false">查看</el-link>
                            </template>
                            <template v-else-if="item.type === 'rich'">
                                <el-button type="primary" plain round v-show="scope.row[scope.column.property]"
                                    @click="$store.commit('showHtml', scope.row[scope.column.property])">查看</el-button>
                            </template>
                            <template v-else-if="item.link">
                                <div v-if="item.key === 'collectiveProcurement' && scope.row.collectiveProcurement"
                                    @click="item.link(scope.row, scope)" class="p_blue">
                                    <p v-for="(item, index) in scope.row.collectiveProcurement.split('、')" :key="index">
                                        {{ item }}</p>
                                </div>
                                <div v-else-if="item.key === 'winningProvince' && scope.row.winningProvince"
                                    @click="item.link(scope.row, scope)" class="p_blue">
                                    <p v-for="(item, index) in scope.row.winningProvince.split('、')" :key="index">
                                        {{ item }}</p>
                                </div>
                                <el-button v-else-if="scope.row[scope.column.property]" type="text"
                                    :style="{ fontSize: item.fontSize }" :title="item.explain"
                                    @click="item.link(scope.row, scope)">{{
                scope.row[scope.column.property] }}</el-button>
                                <p v-else>--</p>
                            </template>
                            <template v-else>
                                <div
                                    :title="zeroToEmpty ? (scope.row[scope.column.property] == 0 ? '' : scope.row[scope.column.property]) : scope.row[scope.column.property]">
                                    {{ zeroToEmpty ? (scope.row[scope.column.property] == 0 ? '--' :
                scope.row[scope.column.property] || '--') : scope.row[scope.column.property] || '--'
                                    }}
                                </div>
                            </template>
                        </slot>
                    </template>
                </el-table-column>

                <el-table-column label="操作" width="50" fixed="right" v-if="cmds.length > 0">
                    <template slot-scope="props">
                        <div class="table-operate-panel">
                            <el-link v-for="(item, i) in cmds" :key="`${i}cmds`"
                                :disabled="!item.whether(props.row, item)" :underline="false" :type="item.type"
                                @click="item.event(props.row, item)">{{ item.txt }}</el-link>
                        </div>
                    </template>
                </el-table-column>

            </el-table>
        </div>

        <el-pagination ref="page" :current-page.sync="pageParam.page" :page-sizes="pageSizes"
            :page-size="pageParam.pageSize" :layout="pageLayout" :total="pageParam.total"
            @size-change="handleSizeChange" @current-change="handleCurrentChange">
            <slot name="page"></slot>
        </el-pagination>
    </div>
</template>

<script>
import variable from '@/util/variable';

const sort = {
    ascending: 'asc',
    descending: 'desc'
}

export default {
    props: {
        // 列表是否在加载中
        loading: {
            type: Boolean,
            default: false
        },

        // 列的表头
        cols: {
            type: Array,
            default: () => {
                return []
            }
        },
        // 表数据
        data: {
            type: Array,
            default: () => {
                return []
            }
        },

        cmds: {
            type: Array,
            default: () => {
                return []
            }
        },
        // 0转空
        zeroToEmpty: {
            type: Boolean,
            default: false
        },
        height: {
            default: 250
        },
        hideBorder: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        },

        // 页码的显示样式
        pageLayout: {
            type: String,
            default: 'total, prev, pager, next, jumper, sizes, slot'
        },
        pageSizes: {
            type: Array,
            default: () => {
                return [10, 20, 50, 100]
            }
        },
        // 页码的相关参数
        pageParam: {
            type: Object,
            default: () => {
                return {
                    page: 1,
                    rows: 20,
                    total: 0
                }
            }
        },
        isSearchLeft: {
            type: Boolean,
            default: false
        },
        tableName: {
            type: String,
            default: ''
        },
        searchCols: {
            type: Array,
            default: () => []
        },
        searchParam: {
            type: Object,
            default: () => { }
        },
        componentList: {
            type: Array,
            default: () => []
        },
        indicationList: {
            type: Array,
            default: () => []
        },
    },
    watch: {
        isSearchLeft: {
            handler(val) {
                console.log('打开', val)
            }
        },
        searchCols: {
            handler(val) {
                console.log('打开searchCols', val)
            }
        },
    },
    data() {
        return {
            variable,
            tableLoaded: false,
            realHeight: 0,

            detail: {
                show: false,
                data: {},
                full: false,
            },

            fieldAuth: false,
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.full) {
                this.realHeight = this.$refs.ele.clientHeight - (this.$refs.page ? this.$refs.page.$el.clientHeight : 0) - 40
            } else {
                this.realHeight = this.height
            }
            this.tableLoaded = true
        })
    },
    computed: {
        list() {
            if (this.cols && this.cols.length > 0) {
                let list = this.cols
                // 判断是否本地隐藏字段
                const needHideFiled = this.$help.store.get(this.sortKey)
                list = list.filter(item => {
                    return !needHideFiled[item.fieldName]
                })
                return list.sort((a, b) => {
                    return a.listSort - b.listSort;
                })
            }
            return [];
        },
    },
    methods: {
        //关闭左侧筛选框
        searchClose() {
            this.$emit('setSearchClose')
            // this.isSearchLeft = false
        },
        handleFilter(cols) {
            // this.searchCols = cols
            // this.getData()
            this.$emit('setHandleFilter', cols)
        },
        getOrder(val) {
            if (val) {
                return 'custom'
            } else {
                return false
            }
        },

        getAlign(cls) {
            switch (cls) {
                case 'tl':
                    return 'left'
                case 'tc':
                    return 'center'
                case 'tr':
                    return 'right'
                default:
                    return 'center'
            }
        },

        // 分页相关
        handleSizeChange(val) {
            this.$emit('handleSizeChange', val)
        },
        handleCurrentChange(val) {
            this.$emit('handleCurrentChange', val)
        },
        // 排序
        handleSort({ column, prop, order }) {
            if (column.sortable === true) {
                return
            }
            const param = {}

            param[prop] = sort[order]

            this.$emit('handleSort', param)
        },
    }
}
</script>