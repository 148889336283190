<template>
   <div>
       <el-select v-model="drugs" filterable remote reserve-keyword :remote-method="getList" :loading="loading" style="width: 100%"
                  @change="change" placeholder="请输入药品关键字" multiple value-key="id">
           <el-option v-for="item in list" :key="item.id" :label="item.comName" :value="item"></el-option>
       </el-select>
   </div>
</template>

<script>
    export default {
        props: {
            form: {},
            col: {},
            value: {},
        },
        watch: {
        },
        data() {
            return {
                drugs: [],
                list: [],
                loading: false,
            }
        },
        created() {
            if (this.value) {
                this.drugs = this.value
                this.list = this.value
            }

            this.getList()
        },
        methods: {
            getList(keyword) {
                this.loading = true
                this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {keyword})
                    .then(res => {
                        this.list = res.data
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            change() {
                this.$set(this.form, this.col.key, this.drugs)
            },
        }
    }
</script>